var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"rounded":"sm","show":_vm.loading}},[_c('AuthWrapper',{scopedSlots:_vm._u([{key:"card",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('div',[_c('h1',[_vm._v("Reset Password")])]),_c('div',[_c('p',[_vm._v("Your new password must be different from previously used passwords")])])]),_c('b-alert',{attrs:{"variant":"danger","show":_vm.errorMessage.length}},[_c('div',{staticClass:"alert-body font-small-2"},[_c('b-card-text',[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}}),_c('small',[_vm._v(_vm._s(_vm.errorMessage))])],1)],1)]),_c('validation-observer',{ref:"simpleRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"New Password","label-for":"reset-password-new"}},[_c('validation-provider',{attrs:{"name":"Password","rules":{
                        required: true,
                        password: true
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('span',{staticClass:"leading"},[_c('PasswordLock')],1),((_vm.password1FieldType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"id":"login-password","name":"reset-password-new","placeholder":"*****","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.password)?_vm._i(_vm.password,null)>-1:(_vm.password)},on:{"change":function($event){var $$a=_vm.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.password=$$c}}}}):((_vm.password1FieldType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"id":"login-password","name":"reset-password-new","placeholder":"*****","type":"radio"},domProps:{"checked":_vm._q(_vm.password,null)},on:{"change":function($event){_vm.password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"id":"login-password","name":"reset-password-new","placeholder":"*****","type":_vm.password1FieldType},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',{staticClass:"trailing"})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"confirm-password"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('span',{staticClass:"leading"},[_c('PasswordLock')],1),((_vm.password2FieldType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cPassword),expression:"cPassword"}],attrs:{"id":"reset-password-confirm","placeholder":"*****","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.cPassword)?_vm._i(_vm.cPassword,null)>-1:(_vm.cPassword)},on:{"change":function($event){var $$a=_vm.cPassword,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.cPassword=$$a.concat([$$v]))}else{$$i>-1&&(_vm.cPassword=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.cPassword=$$c}}}}):((_vm.password2FieldType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cPassword),expression:"cPassword"}],attrs:{"id":"reset-password-confirm","placeholder":"*****","type":"radio"},domProps:{"checked":_vm._q(_vm.cPassword,null)},on:{"change":function($event){_vm.cPassword=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cPassword),expression:"cPassword"}],attrs:{"id":"reset-password-confirm","placeholder":"*****","type":_vm.password2FieldType},domProps:{"value":(_vm.cPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.cPassword=$event.target.value}}}),_c('span',{staticClass:"trailing"})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('b-button',{staticClass:"py-1",attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[_vm._v(" Set New Password ")])],1),_c('p',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{ name:'auth-login' }}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" Back to login ")],1)],1)],1)]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }